import React from 'react';
import useStyles from './index.style';
import { Typography, Grid, Button } from '@material-ui/core';

const UnstakePanel = (props) => {
  const classes = useStyles();
  return (
    <>
      <br />
      <div className={classes.buttonContainer}>
        <div style={{ display: 'flex' }}>
          <Grid item xs={6}>
            <Button
              className={classes.buttonStyle}
              onClick={props.claimMTLXRewards}
              disabled={props.mtlxRewards === 0}
            >Claim MTLX Rewards</Button>
          </Grid>
          <Grid item xs={6} style={{ marginLeft: 16 }}>
            <Button
              className={classes.redeemButtonStyle}
              onClick={props.unStakeLPTokens}
              disabled={props.lpTokensStaked === 0}
            >Exit: Claim and Unstake All</Button>
          </Grid>
        </div>
        <br />
        <Typography className={classes.linkText} >Please use this option to provide liquidity via Uniswap pool tokens once you have added liquidity to
      {props.pool === '0' ?
            <a className={classes.link} href="https://info.uniswap.org/pair/0x1a57ec5459928389fbb5612ff2a5e0b534fd9e2e" target="_blank" rel="noopener noreferrer">
              &nbsp;MTLX-USDT </a>
            : props.pool === '1' ?
              <a className={classes.link} href="https://info.uniswap.org/pair/0xa71d014ffc0d2aff34d1fe1672dcdef6ec00af66" target="_blank" rel="noopener noreferrer">
                &nbsp;MTLX-ETH </a>
              : <a className={classes.link} href="https://info.uniswap.org/pair/0x6c52b805a27e2bcf4e1ded6023904b5c7c53b8b9" target="_blank" rel="noopener noreferrer">
                &nbsp;MTLX-FET </a>
          }
         pool</Typography>
      </div>
    </>
  );
};

export default UnstakePanel;