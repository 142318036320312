import uniswapV2RouterMainnet from '../ABI/UNISWAPV2ROUTER_ABI.json';

class UniswapV2Router {
  constructor(address, networkId) {
    this.UserContract = new window.web3.eth.Contract(uniswapV2RouterMainnet, address);
  }

  getInstance = async () => this.UserContract;
}

let contract = null
const getContract = (address, networkId) => {
  contract = new UniswapV2Router(address, networkId);
  Object.freeze(contract);
  return contract;
}

export default getContract;