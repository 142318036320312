import { all, fork } from 'redux-saga/effects';
import createSagaMiddleware from 'redux-saga';
import startUpSaga from '../saga/startUpSaga';
import connectMetamaskSaga from '../saga/connectMetamask';
import userWalletDetails from '../saga/userWalletDetails';

function* rootSaga() {
  yield all(
    [
      fork(startUpSaga),
      fork(connectMetamaskSaga),
      fork(userWalletDetails)
    ]
  );
};

const sagaMiddleware = createSagaMiddleware();

export const startSaga = () => {
  sagaMiddleware.run(rootSaga);
};

export default sagaMiddleware;
