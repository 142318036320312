import React from 'react';
import CardContent from '@material-ui/core/CardContent';
import { Typography, Grid } from '@material-ui/core';
import useStyles from './index.style'

const TokenStausCard = (props) => {

  const classes = useStyles();

  return (
    <CardContent style={{ padding: 0, marginTop: 20 }}>
      <Typography className={classes.cardTitle}>Wallet Balance</Typography>
      <div style={{ display: "flex", }}>
        <Grid item md={4} xs={12}>
          <div className={classes.grid}>
            <Typography className={classes.contentText}>{props.pool === '0' ? 'USDT' : props.pool === '1' ? 'ETH' : 'FET'}</Typography>
            <Typography className={classes.incrementStyle}>{props.usdtBalance}</Typography>
          </div>
        </Grid>
        <br />
        <Grid item md={4} xs={12} style={{ paddingLeft: 32 }}>
        <div className={`${classes.grid}`}>
            <Typography className={classes.contentText}>MTLX</Typography>
            <Typography className={classes.incrementStyle}>{props.mtlxBalance}</Typography>
          </div>
        </Grid>
        <br />
        <Grid item md={4} xs={12} style={{ paddingLeft: 32 }}>
        <div className={classes.grid}>
            <Typography className={classes.contentText}>Uni-V2</Typography>
            <Typography className={classes.incrementStyle}>{parseFloat(props.lpTokens).toFixed(8)}</Typography>
          </div>
        </Grid>
      </div>
    </CardContent>
  );
};

export default TokenStausCard;