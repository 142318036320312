export const contractAddresses = {
  mtlx: "0x2e1E15C44Ffe4Df6a0cb7371CD00d5028e571d14",
  amplify: '0xEBF56FB1C3029115555680aF6bfD97B6CF541bAB',
  lp: '0x1a57ec5459928389fBB5612fF2a5e0b534fD9E2E',
  proxy: '0xd60C24fda99a50DAB440f3B9B2Bf611C0852e2bD',
  usdt: '0xdac17f958d2ee523a2206206994597c13d831ec7',
  registry: '0xb872B19f6682F1DB65C8351233085bAd5c7b1c13',
  HUniswapV2: '0x5c260c28DfE03c5eb2829BFC66B07EbBF349fe1c',
  HERC20TokensIn: '0x871D6447879d7ccDadC80457731cc1722A8e8aCe',
  UniswapV2Router: '0x7a250d5630B4cF539739dF2C5dAcb4c659F2488D',
  lpETH: '0xa71d014ffc0d2aff34d1fe1672dcdef6ec00af66',
  lpFET: '0x6c52b805a27e2bcf4e1ded6023904b5c7c53b8b9',
  eth: '0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2',
  fet: '0xaea46A60368A7bD060eec7DF8CBa43b7EF41Ad85',
}

export const stakeURL = "https://europe-west2-fetch-ai-sandbox.cloudfunctions.net/stake_amount";
export const mtlxrewardsURL = "https://europe-west2-fetch-ai-sandbox.cloudfunctions.net/mtlx_reward";
export const conversionRateURL = "https://min-api.cryptocompare.com/data/price?fsym=FET&tsyms=USD";
export const exchangeRate = "https://api.coingecko.com/api/v3/coins/fetch-ai/history"
export const rewardsDistributedURL = "https://europe-west2-fetch-ai-sandbox.cloudfunctions.net/get_rewards_distributed";

export const RewardPoolAddress = "0xebf56fb1c3029115555680af6bfd97b6cf541bab";
export const multiplier = 18
export const usdtMultiplier = 6
export const gasFee = 65000000
export const gasLimit = 7000000
export const networkId = 1
export const networkName = 'Mainnet'
export const slippage = 0.96
export const totalTokens = 1000000;

export const networks = {
  Mainnet: 1
}

export const networkNames = {
  1: 'Mainnet'
}

export const truncateDecimals = (amount, decimalDigit) => {
  if (!amount) amount = 0;
  let amountValue = amount.toString();
  if (amountValue.indexOf(".") > 0) {
    amountValue = amountValue.slice(0, (amountValue.indexOf(".")) + decimalDigit + 1)
  } else {
    amountValue = (amountValue + '.').padEnd(decimalDigit, "0")
  }
  return Number(amountValue).toFixed(decimalDigit)
}
