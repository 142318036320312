import React from 'react';
import useStyles from './index.style';
import { Typography, Grid, Button } from '@material-ui/core';

const RedeemPanel = (props) => {
  const classes = useStyles();
  return (
    <>
      <br />
      <div className={classes.buttonContainer}>
        <div style={{ display: 'flex' }}>
          <Grid item xs={6}>
            <Button className={classes.buttonStyle} onClick={props.claimMTLXRewards} disabled={props.mtlxRewards === 0}>Claim MTLX Rewards</Button>
          </Grid>
          <Grid item xs={6} style={{ marginLeft: 16 }}>
            <Button className={classes.redeemButtonStyle} onClick={props.unstakeUSDT} disabled={props.lpTokensStaked === 0}>Exit: Claim and Redeem All</Button>
          </Grid>
        </div>
        <br />
        <Typography style={{
          textAlign: 'center', color: '#7C7C7D',
          fontWeight: 300,
        }} className={classes.textStyle}>Please use this option to provide liquidity using {props.pool === '0' ? 'USDT' : props.pool === '1' ? 'ETH' : 'FET'} only</Typography>
      </div>
      <br />
    </>
  );
};

export default RedeemPanel;