import LPFETMainnet from '../ABI/LPFET_ABI.json';

class LPFET {
  constructor(address, networkId) {
    this.UserContract = new window.web3.eth.Contract(LPFETMainnet, address);
  }

  getInstance = async () => this.UserContract;
}

let contract = null
const getContract = (address, networkId) => {
  contract = new LPFET(address, networkId);
  Object.freeze(contract);
  return contract;
}

export default getContract;