import { call, takeEvery } from 'redux-saga/effects';
import { connectMetamask } from '../reducer/metaMask';
import { isMetamaskInstalled, askPermission } from '../utils';

function* metamaskSaga(action) {
  try {
    const isInstalled = isMetamaskInstalled();
    if (isInstalled) {
      yield call(askPermission);
    }
  } catch (e) {
    console.log('Error: ', e)
  }
}

function* connectMetamaskSaga() {
  yield takeEvery(connectMetamask.type, metamaskSaga);
}

export default connectMetamaskSaga;