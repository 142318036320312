import { createSlice } from '@reduxjs/toolkit'

const configSlice = createSlice({
  name: 'metamask',
  initialState: {
    isAvailable: false,
  },
  reducers: {
    connectMetamask() { },
    updateMetaMask(state, action) {
      state.isAvailable = action.payload.isAvailable;
    },
  }
})

const { actions, reducer } = configSlice;

export const { updateMetaMask, connectMetamask } = actions;

export default reducer;
