import React, { useState, useEffect } from 'react';
import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";
import Backdrop from "@material-ui/core/Backdrop";
import { Typography, MenuItem, Select, Paper, makeStyles } from "@material-ui/core";
import { TokenAmount, Token, Fetcher, ChainId, Route } from "@uniswap/sdk";
import bigNumber from 'bignumber.js';
import AppHeader from '../containers/AppHeader/AppHeader';
import StakeCard from '../containers/StakeCard';
import TokenStatusCard from '../containers/TokenStatusCard';
import TransactionPanel from '../containers/TransactionPanel';
import LPTokensTransaction from '../containers/LPTokensTransaction';
import Refresh from '../assets/loader.svg';
import { contractAddresses, truncateDecimals, multiplier, usdtMultiplier, networkName, totalTokens } from '../constants';
import Loader from '../assets/loadingIconWhite.bin';
import { ethers } from 'ethers';
import moment from 'moment';
import { createAction } from '@reduxjs/toolkit';
import { connect } from 'react-redux';
import { getRewardsDistributed ,getMTLXRewards, updateStakedAmount, getStakedAmount, getStakedAmountDate, getExchangeRate, getconversionRate } from '../utils';
import ContractInitialization from '../utils/contractInitialize';
import WalletModal from '../component/WalletModal';
import Wallet from '../assets/wallet.svg';

const connectWallet = createAction('CONNECT_WALLET')

const useStyles = makeStyles((theme) => ({
  content: {
    position: 'relative',
    zIndex: 10,
    gridGap: '50px',
    marginBottom: '2%',
    padding: 0,
  },
  paper: {
    ...theme.card,
    padding: theme.spacing(4, 3),
  },
  typography: {
    color: theme.secondaryText,
    textAlign: 'center',
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
  title: {
    fontFamily: theme.typography.fontFamilyMedium,
    color: theme.text,
    fontSize: 24,
  },
  gridStyle: {
    textAlign: 'center',
  },
  addressBox: {
    border: '1px solid #4A4D5F',
    boxSizing: 'border-box',
    background: 'transparent',
    width: '178px',
    float: 'right',
    textAlign: 'center',
    color: 'white',
    '& .MuiFormControl-root': {
      '& .MuiInput-underline:before': {
        borderBottom: 'none',
      },
      '& .MuiInput-underline:after': {
        borderBottom: 'none',
      },
      '& .MuiInput-underline:focus': {
        borderBottom: 'none',
      },
      '& .MuiInput-underline:hover': {
        borderBottom: 'none',
      },
    },
  },
  flexDiv: {
    display: 'flex',
    margin: '64px 0 24px',
  },
  heading: {
    color: 'white',
    fontWeight: 'bold'
  },
  menuItem: {
    backgroundColor: `${theme.inputColor} !important`,
    color: theme.text,
    paddingLeft: '10px !important',
    paddingRight: '10px !important',
    fontSize: 14,
    '&.placeholder': {
      backgroundColor: `${theme.inputColor} !important`,
      '&:hover': {
        backgroundColor: `${theme.inputColor} !important`,
      },
    },
    '&:hover': {
      color: theme.grid.color,
      backgroundColor: theme.inputColor,
      borderBottom: 'none !important',
    },
    '&.MuiListItem-root.Mui-disabled': {
      backgroundColor: theme.inputColor,
      color: theme.secondaryText,
      opacity: 1,
    },
    '&.MuiListItem-root.Mui-selected, .MuiListItem-root.Mui-selected:hover': {
      backgroundColor: theme.inputColor,
    },
    '&:before, &:after, &:hover, &:focus': {
      borderBottom: 'none',
    },
  },
  select: {
    color: theme.text,
    fontWeight: 300,
    '&:before, &:after, &:hover, &:focus': {
      borderBottom: 'none',
    },
  },
}));

const App = (props) => {
  const classes = useStyles();

  const [loading, setLoading] = useState(false);
  const [stakedAmount, setStakedAmount] = useState(0.00);
  const [currentValue, setCurrentValue] = useState(0.00);
  const [valueIncrease, setValueIncrease] = useState(0.00);
  const [loadingText, setLoadingText] = useState('Loading');
  const [poolShare, setPoolShare] = useState(0);
  const [usdtBalance, setUSDTBalance] = useState(0);
  const [lpTokens, setLPTokens] = useState(0.00);
  const [APY, setAPY] = useState(0.00);
  const [mtlx, setMTLX] = useState(0.00);
  const [mtlxInUSDT, setMTLXInUSDT] = useState(0.00);
  const [mtlxBalance, setMTLXBalance] = useState(0.00);
  const [lpTokensStaked, setLPTokensStaked] = useState(0.00);
  const [date, setDate] = useState(moment()
    .format('DD MMM YYYY, hh:mm a'));
  const [mtlxValue, setMtlxValue] = useState(0.00);
  const [tokensLeftToFarm, setTokensLeftToFarm] = useState(0.00);
  const [tokensPercent, setTokensPercent] = useState(0.00);
  const [rewardsCollected, setRewardsCollected] = useState(0.00);
  const [rewardsCollectedUSDT, setRewardsCollectedUSDT] = useState(0.00);
  const [pool, setPool] = useState('1');
  const [instance, setInstance] = useState({});
  const [open, setOpen] = useState(true);

  const provider = new ethers.providers.InfuraProvider("mainnet", process.env.REACT_APP_INFURA_PROVIDER);

  const handlePoolChange = (event) => {
    setPool(event.target.value);
  };

  useEffect(() => {
    if (props.user.address) {
      setOpen(false);
      async function fetchData() {
        const value = await ContractInitialization(pool, props.networkID);
        setInstance(value)
      }
      fetchData();
      connectWallet();
    }
  }, [pool, props.networkID, props.user.address])

  useEffect(() => {
    if (Object.keys(instance).length && props.networkID) {
      updateAllBalances();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [instance, props.networkID, pool])

  const getStakeCardBalances = async () => {
    try {
      const userInfo = await instance.amplifyContractInstance.methods.userInfo(parseInt(pool), props.user.address).call();
      const usdtToken = new Token(
        ChainId.MAINNET,
        contractAddresses.usdt,
        usdtMultiplier,
        "USDT",
        "USDTether"
      );

      const mtlxToken = new Token(
        ChainId.MAINNET,
        contractAddresses.mtlx,
        multiplier,
        "MTLX",
        "Mettalex"
      );

      const ethToken = new Token(
        ChainId.MAINNET,
        contractAddresses.eth,
        multiplier,
        "WETH",
        "WrappedETH"
      );

      const fetToken = new Token(
        ChainId.MAINNET,
        contractAddresses.fet,
        multiplier,
        "FET",
        "Fetch.AI"
      );

      let firstToken = usdtToken;
      if (pool === '1') {
        firstToken = ethToken;
      }
      if (pool === '2') {
        firstToken = fetToken;
      }

      Fetcher.fetchPairData(
        firstToken,
        mtlxToken,
        provider
      ).then(async (pair) => {
        const lpTokensTotalSupply = pool === '0'
          ? await instance.lpContractInstance.methods.totalSupply().call()
          : pool === '1' ? await instance.lpethContractInstance.methods.totalSupply().call()
            : await instance.lpfetContractInstance.methods.totalSupply().call();

        const kLast = pool === '0'
          ? await instance.lpContractInstance.methods.kLast().call()
          : pool === '1' ? await instance.lpethContractInstance.methods.kLast().call()
            : await instance.lpfetContractInstance.methods.kLast().call();

        const lpToken = new Token(
          ChainId.MAINNET,
          pool === '0' ? contractAddresses.lp : pool === '1' ? contractAddresses.lpETH : contractAddresses.lpFET,
          multiplier,
          pool === '0' ? await instance.lpContractInstance.methods.symbol().call() : pool === '1' ? await instance.lpethContractInstance.methods.symbol().call() : await instance.lpfetContractInstance.methods.symbol().call(),
          pool === '0' ? await instance.lpContractInstance.methods.name().call() : pool === '1' ? await instance.lpethContractInstance.methods.name().call() : await instance.lpfetContractInstance.methods.name().call()
        );
        const first = pair.getLiquidityValue(
          firstToken,
          new TokenAmount(lpToken, lpTokensTotalSupply.toString()),
          new TokenAmount(lpToken, userInfo.amount),
          true,
          kLast
        );

        const mtlx = pair.getLiquidityValue(
          mtlxToken,
          new TokenAmount(lpToken, lpTokensTotalSupply.toString()),
          new TokenAmount(lpToken, userInfo.amount),
          true,
          kLast
        );

        let route = new Route([pair], mtlxToken);
        let currentAmount = new bigNumber(first.toExact()).plus(new bigNumber(mtlx.toExact()).multipliedBy(route.midPrice.toSignificant(8))).toNumber();
        const lpTokensStakedAmount = new bigNumber(userInfo.amount).div(new bigNumber(10 ** multiplier)).toNumber();

        let stakedValue = await getStakedAmount(props.user.address, pool === '0' ? 'USDT' : pool === '1' ? 'ETH' : 'FET', props.networkID);
        if (stakedValue) {
          setStakedAmount(new bigNumber(stakedValue).toString());
        } else if (currentAmount) {
          stakedValue = currentAmount;
          setStakedAmount(new bigNumber(currentAmount).toString());
        } else {
          setStakedAmount(0.00);
        }

        const pendingMTLX = await instance.amplifyContractInstance.methods.pendingMTLX(parseInt(pool), props.user.address).call();
        let rate;

        if (pool === '2') {
          rate = await getconversionRate();
          setCurrentValue(currentAmount * rate);
        } else setCurrentValue(currentAmount);

        const mtlxPerBlock = await instance.amplifyContractInstance.methods.MTLXPerBlock().call();
        const mtlxPrice = route.midPrice.toFixed(pool === '0' ? 3 : 6);
        setMtlxValue(mtlxPrice)
        const blocksPerDay = 4 * 60 * 24;
        let totalStacked = await instance.lpContractInstance.methods.balanceOf(contractAddresses.amplify).call();
        if (pool === '1') {
          totalStacked = await instance.lpethContractInstance.methods.balanceOf(contractAddresses.amplify).call();
        }
        if (pool === '2') {
          totalStacked = await instance.lpfetContractInstance.methods.balanceOf(contractAddresses.amplify).call();
        }
        const last24HoursYield = parseFloat(mtlxPerBlock / (10 ** multiplier)) * mtlxPrice * blocksPerDay * (stakedValue / parseFloat(totalStacked / (10 ** multiplier)));
        const annualYield = last24HoursYield * 365;
        setAPY(truncateDecimals(((annualYield / stakedValue) * 100), 2));
        setMTLX(new bigNumber(pendingMTLX).div(10 ** multiplier).toNumber());
        let routeMTLX = route;
        // if (pool !== '1') {
        const usdtMtlxPair = await Fetcher.fetchPairData(
          usdtToken,
          mtlxToken,
          provider
        )
        routeMTLX = new Route([usdtMtlxPair], mtlxToken);
        // }
        let mtlxRewardInUSDT = new bigNumber(pendingMTLX).div(new bigNumber(10 ** multiplier)).multipliedBy(new bigNumber(routeMTLX.midPrice.toFixed(8)));
        let mtlxRewardsCollected = await getMTLXRewards(props.user.address, pool === '0' ? 'USDT' : pool === '1' ? 'ETH' : 'FET', props.networkID);
        let mtlxRewardsCollectedUSDT = 0;
        if (!mtlxRewardsCollected) {
          mtlxRewardsCollected = 0;
        }
        setRewardsCollected(mtlxRewardsCollected);
        if (mtlxRewardsCollected !== 0) {
          mtlxRewardsCollectedUSDT = new bigNumber(mtlxRewardsCollected).multipliedBy(new bigNumber(routeMTLX.midPrice.toSignificant(8))).toNumber();
        }

        setRewardsCollectedUSDT(mtlxRewardsCollectedUSDT);
        setMTLXInUSDT(mtlxRewardInUSDT.toFixed(2));

        if (!stakedValue || stakedValue === 0 || new bigNumber(currentAmount).eq(new bigNumber(0)) || lpTokensStakedAmount === 0) {
          setValueIncrease(0);
        } else {
          if (pool === '1') {
            currentAmount = new bigNumber(currentAmount).div(route.midPrice.toSignificant(8)).multipliedBy(routeMTLX.midPrice.toSignificant(8)).toNumber();
            stakedValue = new bigNumber(stakedValue).div(route.midPrice.toSignificant(8)).multipliedBy(routeMTLX.midPrice.toSignificant(8)).toNumber();
            mtlxRewardsCollectedUSDT = new bigNumber(mtlxRewardsCollected).multipliedBy(new bigNumber(routeMTLX.midPrice.toSignificant(8))).toNumber();
            mtlxRewardInUSDT = new bigNumber(pendingMTLX).div(new bigNumber(10 ** multiplier)).multipliedBy(new bigNumber(routeMTLX.midPrice.toFixed(8)));
          }
          if (pool === '2') {
            let date = await getStakedAmountDate(props.user.address, 'FET', props.networkID);
            let value;
            if (date === null) {
              date = await moment().format('DD-MM-YYYY')
              value = await getExchangeRate(date);
            } else
              value = await getExchangeRate(moment(date).format('DD-MM-YYYY'));
            const changeValue = ((parseFloat(currentAmount * rate) + mtlxRewardInUSDT.toNumber() + mtlxRewardsCollectedUSDT - (stakedValue * value.usd)) / (stakedValue * value.usd)) * 100;
            setValueIncrease(changeValue.toFixed(3));
          } else
            setValueIncrease((((parseFloat(currentAmount) + mtlxRewardInUSDT.toNumber() + mtlxRewardsCollectedUSDT - stakedValue) / stakedValue) * 100).toFixed(3));
        }
        setRewardsCollectedUSDT(mtlxRewardsCollectedUSDT);
        setDate(moment()
          .format('MMM DD YYYY hh:mm a'))
        setLPTokensStaked(new bigNumber(userInfo.amount).div(new bigNumber(10 ** 18)).toNumber());
      });
    } catch (e) {
      console.log(e);
    }
  };

  const getPoolStatus = async () => {
    try {
      const userInfo = await instance.amplifyContractInstance.methods.userInfo(pool, props.user.address).call();
      let lpTokenBalanceAmplify = await instance.lpContractInstance.methods.balanceOf(contractAddresses.amplify).call();
      if (pool === '1') {
        lpTokenBalanceAmplify = await instance.lpethContractInstance.methods.balanceOf(contractAddresses.amplify).call();
      }
      if (pool === '2') {
        lpTokenBalanceAmplify = await instance.lpfetContractInstance.methods.balanceOf(contractAddresses.amplify).call();
      }

      let poolshare = ((parseFloat(userInfo.amount) / lpTokenBalanceAmplify) * 100).toFixed(2)
      setPoolShare(new bigNumber(lpTokenBalanceAmplify).eq(0) ? 0 : poolshare);
      
      let mtlxLeftToFarm = await getRewardsDistributed(props.networkID);
      mtlxLeftToFarm = new bigNumber(totalTokens).minus(new bigNumber(mtlxLeftToFarm).div(10 ** 18)).toNumber()
      setTokensLeftToFarm(mtlxLeftToFarm);
      setTokensPercent(100 - ((totalTokens - mtlxLeftToFarm) / totalTokens) * 100);
    }
    catch (e) {
      console.log(e);
    }
  };

  const getWalletBalance = async () => {
    try {
      const usdt = await instance.mrc20ContractInstance.methods.balanceOf(props.user.address).call();
      const mtlxTokensAmount = await instance.mtlxContractInstance.methods.balanceOf(props.user.address).call();
      let balance;
      if (pool === '0') {
        balance = new bigNumber(usdt).div(new bigNumber(10 ** usdtMultiplier)).toString();
      } else if (pool === '1') {
        balance = new bigNumber(await window.web3.eth.getBalance(props.user.address)).div(new bigNumber(10 ** multiplier)).toString();
      } else {
        balance = new bigNumber(usdt).div(new bigNumber(10 ** multiplier)).toString();
      }
      setMTLXBalance(truncateDecimals(parseFloat(mtlxTokensAmount) / (10 ** multiplier), 2));
      setUSDTBalance(balance);
    }
    catch (err) {
      console.log(err);
    }
  };

  const getLPTokens = async () => {
    try {
      let LPTokensAmount = 0;
      if (pool === '0') {
        LPTokensAmount = await instance.lpContractInstance.methods.balanceOf(props.user.address).call();
      } else if (pool === '1') {
        LPTokensAmount = await instance.lpethContractInstance.methods.balanceOf(props.user.address).call();
      } else {
        LPTokensAmount = await instance.lpfetContractInstance.methods.balanceOf(props.user.address).call();
      }
      setLPTokens(new bigNumber(LPTokensAmount).div(10 ** multiplier).toString());
    }
    catch (err) {
      console.log(err);
    }
  };

  const updateAllBalances = async () => {
    setLoading(true);
    setLoadingText('Fetching balances...');
    await getStakeCardBalances();
    await getPoolStatus();
    await getWalletBalance();
    await getLPTokens();
    setLoadingText('Loading');
    setLoading(false);
  };

  const renderHeading = () => {
    return <div className={classes.flexDiv}>
      <Grid item md={2}>
        <Typography className={classes.heading} variant='h4'>Amplify</Typography>
      </Grid>
      <Grid item md={8}>
        <Typography variant='subtitle1' className={classes.addressText} style={{ padding: 8, textAlign: 'center', color: 'white', fontWeight: 500 }}>MTLX: {mtlxValue} {pool === '0' ? 'USDT' : pool === '1' ? 'ETH' : 'FET'}</Typography>
      </Grid>
      <Grid item md={2}>
        <Paper className={classes.addressBox}>
          <img src={Wallet} alt='wallet' /> &nbsp;&nbsp;
          <Select
            className={`${classes.select} select`}
            value={pool}
            onChange={(event) => handlePoolChange(event)}
          >
            <MenuItem value="0" className={classes.menuItem}>MTLX-USDT Pool</MenuItem>
            <MenuItem value="1" className={classes.menuItem}>MTLX-ETH Pool</MenuItem>
            <MenuItem value="2" className={classes.menuItem}>MTLX-FET Pool</MenuItem>
          </Select>
        </Paper>
      </Grid>
    </div>
  };

  const formatNumber = (num) => {
    return parseFloat(num).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  const formatNumberwOutDecimal = (num) => {
    return parseFloat(num).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  const renderStakeCard = () => (
    <Grid item xs={12}>
      <StakeCard
        stakedAmount={stakedAmount}
        currentValue={currentValue}
        valueIncrease={formatNumberwOutDecimal(valueIncrease)}
        getStakeCardBalance={getStakeCardBalances}
        APY={APY}
        mtlxAcc={formatNumber(mtlx)}
        mtlxAccInUSDT={formatNumber(mtlxInUSDT)}
        poolShare={formatNumber(poolShare)}
        lpTokensStaked={lpTokensStaked}
        currentDate={date}
        tokensLeftToFarm={tokensLeftToFarm}
        tokensPercent={tokensPercent}
        rewardsCollected={rewardsCollected}
        rewardsCollectedUSDT={rewardsCollectedUSDT}
        getPoolStatus={getPoolStatus}
        pool={pool}
        updateAllBalances={updateAllBalances}
        networkSelected={props.networkID}
      />
    </Grid>
  );

  const renderTokenStatusCard = () => (
    <Grid item xs={12}>
      <TokenStatusCard
        usdtBalance={formatNumber(usdtBalance)}
        mtlxBalance={formatNumber(mtlxBalance)}
        lpTokens={lpTokens}
        pool={pool}
        networkSelected={props.networkID}
      />
    </Grid>
  );

  const renderTransactioPanel = () => (
    <Grid style={{ padding: '16px 0 16px 16px' }} item xs={6}>
      <TransactionPanel
        // drizzle={props.drizzle}
        setLoading={setLoading}
        // drizzleState={props.drizzleState}
        updateBalances={updateAllBalances}
        setLoadingText={setLoadingText}
        stakedAmount={stakedAmount}
        usdtBalance={usdtBalance}
        address={props.user.address}
        mtlxRewards={mtlx}
        updateStakedAmount={updateStakedAmount}
        lpTokensStaked={lpTokensStaked}
        getStakedAmount={getStakedAmount}
        getMTLXRewards={getMTLXRewards}
        mtlxValue={mtlxValue}
        pool={pool}
        networkSelected={props.networkID}
        lpContract={instance.lpContractInstance}
        amplifyContract={instance.amplifyContractInstance}
        lpethContract={instance.lpethContractInstance}
        lpfetContract={instance.lpfetContractInstance}
        mrc20Contract={instance.mrc20ContractInstance}
        mtlxContract={instance.mtlxContractInstance}
        hrc20inContract={instance.hrc20inContractInstance}
        huniswapContract={instance.huniswapContractInstance}
        uniswapContract={instance.uniswapContractInstance}
        proxyContract={instance.proxyContractInstance}
      />
    </Grid>
  );

  const renderLPTokensTransaction = () => (
    <Grid item xs={6}>
      <LPTokensTransaction
        // drizzle={props.drizzle}
        address={props.user.address}
        updateBalances={updateAllBalances}
        setLoading={setLoading}
        setLoadingText={setLoadingText}
        stakedAmount={stakedAmount}
        lpTokens={lpTokens}
        mtlxRewards={mtlx}
        updateStakedAmount={updateStakedAmount}
        lpTokensStaked={lpTokensStaked}
        getStakedAmount={getStakedAmount}
        getMTLXRewards={getMTLXRewards}
        mtlxValue={mtlxValue}
        pool={pool}
        networkSelected={props.networkID}
        lpContract={instance.lpContractInstance}
        amplifyContract={instance.amplifyContractInstance}
        lpethContract={instance.lpethContractInstance}
        lpfetContract={instance.lpfetContractInstance}
        mrc20Contract={instance.mrc20ContractInstance}
        mtlxContract={instance.mtlxContractInstance}
      />
    </Grid>
  );

  const renderBody = () => (
    <Container className={`${classes.content} content`}>
      {renderHeading()}
      <br />
      <Grid container spacing={4}>
        {renderStakeCard()}
      </Grid>
      <Grid container spacing={4}>
        {renderTransactioPanel()}
        {renderLPTokensTransaction()}
      </Grid>
      <Grid container spacing={4}>
        {renderTokenStatusCard()}
      </Grid>
    </Container>
  );

  const renderNetworkBackdrop = () => (
    <Backdrop className={classes.backdrop} open={props.networkID !== 1}>
      <Grid className={classes.gridStyle}>
        <img src={Refresh} alt="lock" width="20%" />
        <Typography className={classes.typographyBackdrop}>Please connect to {networkName} network and Refresh</Typography>
      </Grid>
    </Backdrop>
  );

  const renderLoadingBackdrop = () => (
    <Backdrop className={classes.backdrop} open={loading}>
      <Grid className={classes.gridStyle}>
        <img src={Loader} alt="lock" width="20%" />
        <Typography
          className={classes.typographyBackdropLoading}
        >{loadingText}</Typography>
      </Grid>
    </Backdrop>
  );

  return (
    <>
      {props.networkID && renderNetworkBackdrop()}
      {loading && renderLoadingBackdrop()}
      <WalletModal open={open} />
      <AppHeader
        address={props.user.address}
        id={props.networkID}
      />
      {!open && renderBody()}
      <div id="background" />
    </>
  );
}

const mapStateToProps = (state) => ({
  user: state.user,
  network: state.user.network,
  networkID: state.user.networkID
});

export default connect(mapStateToProps, { connectWallet })(App)
