import React, { useState } from 'react';
import CardContent from '@material-ui/core/CardContent';
import { Typography, Tooltip, Box } from '@material-ui/core';
import { TokenAmount, Token, Fetcher, ChainId, Route } from "@uniswap/sdk";
import { ethers } from 'ethers';
import bigNumber from 'bignumber.js';
import useStyles from './index.style';
import { multiplier, contractAddresses, usdtMultiplier, gasFee } from '../../constants';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import StakePanel from '../../component/StakePanel';
import UnstakePanel from '../../component/UnstakePanel';

const provider = new ethers.providers.InfuraProvider("mainnet", process.env.REACT_APP_INFURA_PROVIDER);

function TabPanel(props) {
  const classes = useStyles()
  const { children, value, index, ...other } = props;

  return (
    <Box
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      className={classes.tabPanel}
      {...other}
    >
      {value === index && (
        <Box p={1}>
          <Typography component={'span'}>{children}</Typography>
        </Box>
      )}
    </Box>
  );
}

const LPTokensTransaction = (props) => {
  const classes = useStyles();

  const [amountToStake, setAmountToStake] = useState('');
  const [errorText, setErrorText] = useState('');
  const [tab, setTab] = useState(0);
  const tabsTitle = ["Stake", "Unstake"]

  const handleInputChange = (event) => {
    setErrorText('');
    setAmountToStake(event.target.value);
  };

  const handleMaxInput = () => {
    setErrorText('');
    setAmountToStake(props.lpTokens);
  };

  const stakeLPTokens = async () => {

    let amount = parseFloat(amountToStake);

    if (amountToStake === '') {
      setErrorText('Please enter an amount');
      return;
    }

    if (parseFloat(amount) <= 0) {
      setErrorText('Amount can not be zero please enter valid amount');
      return;
    }

    if (parseFloat(amount) > props.lpTokens) {
      setErrorText(`Insufficient wallet balance (${props.lpTokens})`);
      return;
    }

    try {
      props.setLoading(true);
      props.setLoadingText('Approving UNI V2 LP tokens...');
      const userBalance = window.web3.utils.toBN(new bigNumber(amountToStake).multipliedBy(10 ** multiplier));
      const pendingMTLX = await props.amplifyContract.methods.pendingMTLX(parseInt(props.pool), props.address).call();
      if (props.pool === '0') {
        await props.lpContract.methods.approve(
          contractAddresses.amplify,
          userBalance
        ).send({ from: props.address, gasFee: gasFee });
      } else if (props.pool === '1') {
        await props.lpethContract.methods.approve(
          contractAddresses.amplify,
          userBalance
        ).send({ from: props.address, gasFee: gasFee });
      } else {
        await props.lpfetContract.methods.approve(
          contractAddresses.amplify,
          userBalance
        ).send({ from: props.address, gasFee: gasFee });
      }

      props.setLoadingText('Depositing the UNI V2 LP tokens in Amplify...');
      await props.amplifyContract.methods.deposit(props.pool, userBalance).send({ from: props.address, gasFee: gasFee });
      const amountStaked = new bigNumber(amountToStake).multipliedBy(10 ** multiplier).toNumber();
      await updateStakedAmount(amountStaked, pendingMTLX);
      props.updateBalances();
      props.setLoadingText('Loading..');
      props.setLoading(false);
    }
    catch (err) {
      console.log(err);
      props.updateBalances();
      props.setLoadingText('Loading..');
      props.setLoading(false);
    }
  };

  const unStakeLPTokens = async () => {
    try {
      props.setLoading(true);
      props.setLoadingText('Unstaking..');
      const userInfo = await props.amplifyContract.methods.userInfo(parseInt(props.pool), props.address).call();
      // const pendingMTLX = await props.drizzle.contracts.Amplify.methods.pendingMTLX(parseInt(props.pool), props.address).call();
      await props.amplifyContract.methods.withdraw(parseInt(props.pool), window.web3.utils.toBN(userInfo.amount)).send({ from: props.address, gasFee: gasFee });
      const savedStakedAmount = await props.getStakedAmount(props.address, props.pool === '0' ? 'USDT' : props.pool === '1' ? 'ETH' : 'FET', props.networkSelected);
      // const mtlxReward = new bigNumber(pendingMTLX).div(new bigNumber(10 ** multiplier)).toNumber();
      const rewards = await props.getMTLXRewards(props.address, props.pool === '0' ? 'USDT' : props.pool === '1' ? 'ETH' : 'FET', props.networkSelected);
      await props.updateStakedAmount(props.address, savedStakedAmount * (-1), rewards * (-1), props.pool === '0' ? 'USDT' : props.pool === '1' ? 'ETH' : 'FET', props.networkSelected);
      props.updateBalances();
      props.setLoadingText('Loading..');
      props.setLoading(false);
    }
    catch (err) {
      console.log(err);
      props.updateBalances();
      props.setLoadingText('Loading..');
      props.setLoading(false);
    }
  };

  const updateStakedAmount = (amount, pendingMTLX) => {
    const usdtToken = new Token(
      ChainId.MAINNET,
      contractAddresses.usdt,
      usdtMultiplier,
      "USDT",
      "USDTether"
    );

    const ethToken = new Token(
      ChainId.MAINNET,
      contractAddresses.eth,
      multiplier,
      "WETH",
      "WrappedETH"
    );

    const fetToken = new Token(
      ChainId.MAINNET,
      contractAddresses.fet,
      multiplier,
      "FET",
      "FET"
    );

    const mtlxToken = new Token(
      ChainId.MAINNET,
      contractAddresses.mtlx,
      multiplier,
      "MTLX",
      "Mettalex"
    );

    let firstToken = usdtToken;
    if (props.pool === '1') {
      firstToken = ethToken;
    }
    if (props.pool === '2') {
      firstToken = fetToken;
    }

    Fetcher.fetchPairData(
      firstToken,
      mtlxToken,
      provider
    ).then(async (pair) => {
      const lpTokensTotalSupply = props.pool === '0'
        ? await props.lpContract.methods.totalSupply().call()
        : props.pool === '1' ? await props.lpethContract.methods.totalSupply().call()
          : await props.lpfetContract.methods.totalSupply().call();
      const kLast = props.pool === '0'
        ? await props.lpContract.methods.kLast().call()
        : props.pool === '1' ? await props.lpethContract.methods.kLast().call()
          : await props.lpfetContract.methods.kLast().call();
      const lpToken = new Token(
        ChainId.MAINNET,
        props.pool === '0' ? contractAddresses.lp : props.pool === '1' ? contractAddresses.lpETH : contractAddresses.lpFET,
        multiplier,
        props.pool === '0' ? await props.lpContract.methods.symbol().call() : props.pool === '1' ? await props.lpethContract.methods.symbol().call() : await props.lpfetContract.methods.symbol().call(),
        props.pool === '0' ? await props.lpContract.methods.name().call() : props.pool === '1' ? await props.lpethContract.methods.name().call() : await props.lpfetContract.methods.name().call()
      );
      const first = pair.getLiquidityValue(
        firstToken,
        new TokenAmount(lpToken, lpTokensTotalSupply.toString()),
        new TokenAmount(lpToken, amount),
        true,
        kLast
      );
      const mtlx = pair.getLiquidityValue(
        mtlxToken,
        new TokenAmount(lpToken, lpTokensTotalSupply.toString()),
        new TokenAmount(lpToken, amount),
        true,
        kLast
      );
      const route = new Route([pair], mtlxToken);
      const mtlxReward = new bigNumber(pendingMTLX).div(new bigNumber(10 ** multiplier)).toNumber();
      const amountStaked = new bigNumber(mtlx.toExact()).multipliedBy(new bigNumber(route.midPrice.toSignificant(8))).plus(new bigNumber(first.toExact())).toNumber();
      await props.updateStakedAmount(props.address, amountStaked, mtlxReward, props.pool === '0' ? 'USDT' : props.pool === '1' ? 'ETH' : 'FET', props.networkSelected);
      props.updateBalances();
      setAmountToStake('');
      props.setLoadingText('Loading..');
      props.setLoading(false);
    })
  };

  const handleChange = (event, newValue) => {
    setTab(newValue);
  };

  const claimMTLXRewards = async () => {
    try {
      props.setLoading(true);
      const address = props.address;
      const pendingMTLX = await props.amplifyContract.methods.pendingMTLX(parseInt(props.pool), address).call();
      await props.amplifyContract.methods.withdraw(parseInt(props.pool), 0).send({ from: address, gasFee: gasFee });
      const mtlxReward = new bigNumber(pendingMTLX).div(new bigNumber(10 ** multiplier)).toNumber();
      await props.updateStakedAmount(address, 0, mtlxReward, props.pool === '0' ? 'USDT' : props.pool === '1' ? 'ETH' : 'FET', props.networkSelected);
      props.updateBalances();
      props.setLoading(false);
    }
    catch (err) {
      console.log(err);
      props.updateBalances();
      props.setLoading(false);
    }
  };

  return (
    <CardContent className={classes.cardContent}>
      <Tabs TabIndicatorProps={{
        style: {
          backgroundColor: "#68dbda"
        }
      }} value={tab} onChange={handleChange} className={classes.tabs} >
        <Tab className={tab === 0 ? classes.tab : classes.disabledTab} label={
          tabsTitle[0]
        } />
        <Tab className={tab === 1 ? classes.tab : classes.disabledTab} label={
          tabsTitle[1]
        } />
        <Tooltip
          style={{ display: "inline", marginRight: "5px" }}
        >
          <Typography className={classes.icon}>{props.pool === '0' ? 'UNIV2-USDT' : props.pool === '1' ? 'UNIV2-ETH' : 'UNIV2-FET'}</Typography>
        </Tooltip>
      </Tabs>
      <TabPanel value={tab} index={0} className={`${classes.tabContainer} tabs`}>
        <StakePanel
          amountToStake={amountToStake}
          handleMaxInput={handleMaxInput}
          handleInputChange={handleInputChange}
          stakeLPTokens={stakeLPTokens}
          errorText={errorText}
          pool={props.pool}
        />
      </TabPanel>
      <TabPanel value={tab} index={1} className={`${classes.tabContainer} tabs`}>
        <UnstakePanel
          claimMTLXRewards={claimMTLXRewards}
          mtlxRewards={props.mtlxRewards}
          unStakeLPTokens={unStakeLPTokens}
          lpTokensStaked={props.lpTokensStaked}
          pool={props.pool}
        />
        {/* {renderUnstaking()} */}
      </TabPanel>
    </CardContent>
  );
};

export default LPTokensTransaction;