import React from "react";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import Slider from "@material-ui/core/Slider";

const useStyles = makeStyles((theme) => ({
  margin: {
    height: theme.spacing(3)
  },
  root: {
    padding: "0 20px",
    flex: parseInt(Math.ceil(window.screen.width / 96)),
  }
}));

const CustomSlider = withStyles({
  markLabel: {
    color: "#68DBDA",
    height: '5px'
  },
  disabled: {
    color: "#68DBDA !important",
    padding: "13px 0"
  },
  track: {
    height: '5px'
  },
  rail: {
    backgroundColor: "#9098AC"
  },
  valueLabel: {
    left: "calc(-12% + 24px)",
    top: '6px',
    color: '#000000 !important',
    fontSize: '12px',
    "& *": {
      background: "transparent",
      color: "#000"
    }
  },
  mark: {
    position: 'absolute',
    backgroundColor: "#2C2D3D",
    height: '5px',
    width: 1,
  },
  thumb: {
    height: '21px !important',
    width: '65px !important',
    borderRadius: 0,
    backgroundColor: "#68DBDA",
    marginTop: '-11.5px !important',
    marginLeft: '-20px !important',
  }
})(Slider);

const CustomizedSlider = ({ min, max, value, label }) => {
  const classes = useStyles();
  const steps = (max - min) / 40
  const marks = Array.from(new Array(40).keys()).map((_val, index) => ({ value: min + (steps * index) }))

  return (
    <div className={classes.root}>
      <CustomSlider
        className={`${classes.slider} color-slider`}
        disabled
        max={max}
        min={min}
        defaultValue={value}
        // value={label}
        aria-labelledby="disabled-slider"
        marks={marks}
        valueLabelDisplay="on"
        valueLabelFormat={value => <div>{label}</div>}
      />
    </div>
  );
}

export default CustomizedSlider;
