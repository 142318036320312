import lpMainnet from '../ABI/LP_ABI.json';

class LP {
  constructor(address, networkId) {
    this.UserContract = new window.web3.eth.Contract(lpMainnet, address);
  }

  getInstance = async () => this.UserContract;
}

let contract = null
const getContract = (address, networkId) => {
  contract = new LP(address, networkId);
  Object.freeze(contract);
  return contract;
}

export default getContract;