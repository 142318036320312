import { createSlice } from '@reduxjs/toolkit'

const configSlice = createSlice({
  name: 'user',
  initialState: {
    address: '',
    balances: {},
    network: '',
    networkID: '',
    isLoading: false,
    loadingMessage: '',
  },
  reducers: {
    startUpdateAccount() { },
    updateAccount(state, action) {
      state.address = action.payload;
    },
    updateBalance(state, action) {
      state.balances[action.payload.id] = action.payload.value
    },
    updateNetwork(state, action) {
      state.network = action.payload
    },
    updateNetworkID(state, action) {
      state.networkID = action.payload
    },
    updateIsLoading(state, action) {
      state.isLoading = action.payload
    },
    updateLoadingMessage(state, action) {
      state.loadingMessage = action.payload
    },
  }
})

const { actions, reducer } = configSlice;

export const { updateAccount, updateBalance, updateNetwork, updateNetworkID, updateIsLoading, updateLoadingMessage } = actions;

export default reducer;
