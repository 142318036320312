import React from 'react';
import { ThemeProvider } from '@material-ui/core';
import { darkTheme } from '../Theme';
import dotenv from "dotenv";
import App from './App';
import '../index.style';
import { startSaga } from './rootSaga';
dotenv.config();

// eslint-disable-next-line react/prefer-stateless-function
class ThemeSelector extends React.Component {
  render() {
    return (
      <ThemeProvider theme={ darkTheme }>
        <App />
      </ThemeProvider>
    );
  }
}

export default () => {
  startSaga();
  return <ThemeSelector />
};
