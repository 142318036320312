
import AmplifyContract from '../contractInterface/amplifyContract';
import LPContract from '../contractInterface/lpContract';
import LPETHContract from '../contractInterface/lpethContract';
import LPFETContract from '../contractInterface/lpfetContract';
import MRC20Contract from '../contractInterface/mockERC20Contract';
import MTLXContract from '../contractInterface/mtlxContract';
import Hrc20InContract from '../contractInterface/hrc20tokeninContract';
import HuniswapContract from '../contractInterface/huniswapV2Contract';
import UniswapContract from '../contractInterface/uniswapV2routerContract';
import ProxyContract from '../contractInterface/proxyContract';
import { contractAddresses } from '../constants';

const ContractInitialization = async (pool, networkID) => {
  const amplifyContract = AmplifyContract(contractAddresses.amplify, networkID);
  const amplifyContractInstance = await amplifyContract.getInstance();

  const lpContract = LPContract(contractAddresses.lp, networkID);
  const lpContractInstance = await lpContract.getInstance();

  const lpethContract = LPETHContract(contractAddresses.lpETH);
  const lpethContractInstance = await lpethContract.getInstance();

  const lpfetContract = LPFETContract(contractAddresses.lpFET);
  const lpfetContractInstance = await lpfetContract.getInstance();

  const mrc20Contract = MRC20Contract(pool === '2' ? contractAddresses.fet : contractAddresses.usdt, networkID);
  const mrc20ContractInstance = await mrc20Contract.getInstance();

  const mtlxContract = MTLXContract(contractAddresses.mtlx, networkID);
  const mtlxContractInstance = await mtlxContract.getInstance();

  const hrc20inContract = Hrc20InContract(contractAddresses.HERC20TokensIn, networkID);
  const hrc20inContractInstance = await hrc20inContract.getInstance();

  const huniswapContract = HuniswapContract(contractAddresses.HUniswapV2, networkID);
  const huniswapContractInstance = await huniswapContract.getInstance();

  const uniswapContract = UniswapContract(contractAddresses.UniswapV2Router, networkID);
  const uniswapContractInstance = await uniswapContract.getInstance();

  const proxyContract = ProxyContract(contractAddresses.proxy, networkID);
  const proxyContractInstance = await proxyContract.getInstance();

  return {
    amplifyContractInstance,
    lpContractInstance,
    lpethContractInstance,
    lpfetContractInstance,
    mrc20ContractInstance,
    mtlxContractInstance,
    hrc20inContractInstance,
    huniswapContractInstance,
    uniswapContractInstance,
    proxyContractInstance
  }
}

export default ContractInitialization;