import mockERC20Mainnet from '../ABI/MOCKERC20_ABI.json';

class MockERC20 {
  constructor(address, networkId) {
    this.UserContract = new window.web3.eth.Contract(mockERC20Mainnet, address);
  }

  getInstance = async () => this.UserContract;
}

let contract = null
const getContract = (address, networkId) => {
  contract = new MockERC20(address, networkId);
  Object.freeze(contract);
  return contract;
}

export default getContract;