import React from 'react';
import CardContent from '@material-ui/core/CardContent';
import { Typography, Grid } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import bigNumber from 'bignumber.js';
import useStyles from './index.style';
import { truncateDecimals } from '../../constants';
import Slider from './Slider';
import Refresh from '../../assets/refresh.svg';
// import Info from '../../assets/info.svg';

const StakeCard = (props) => {
  const classes = useStyles();

  const getProperFormat = () => {
    let tokensLeftToFarm = (props.tokensLeftToFarm?(props.tokensLeftToFarm + 275000):0)
    if (tokensLeftToFarm >= 1000000) {
      return `${parseFloat(truncateDecimals(tokensLeftToFarm / (10 ** 6), 6))}M`;
    } else if (tokensLeftToFarm >= 100000) {
      return `${parseFloat(truncateDecimals(tokensLeftToFarm / (10 ** 3), 6))}K`;
    } else if (tokensLeftToFarm >= 10000) {
      return `${parseFloat(truncateDecimals(tokensLeftToFarm / (10 ** 3), 6))}K`;
    } else if (tokensLeftToFarm >= 1000) {
      return `${parseFloat(truncateDecimals(tokensLeftToFarm / (10 ** 3), 6))}K`;
    }
    return tokensLeftToFarm;
  };

  return (
    <Grid container spacing={2}>
      <Grid item md={6} sm={12}>
        <div className={classes.headerContainer}>
          <div style={{ display: 'flex', width: '12vh' }}>
            <Typography className={classes.cardTitle}>
              My Stake
            </Typography>&nbsp;&nbsp;
            <div style={{ cursor: 'pointer', margin: '6px 0' }}>
              <img onClick={() => { props.updateAllBalances() }} src={Refresh} alt="lock"></img>
            </div>
          </div>
          <Typography className={classes.cardContentLight}>
            Updated: {props.currentDate}
          </Typography>
        </div>
        <CardContent className={classes.cardContent}>
          <Grid className={classes.dotted}>
            <Grid item xs={6} >
              <Typography className={`${classes.typography} ${classes.greyTypography}`}>
                Uni-V2 Tokens Staked
                </Typography>
            </Grid>
            <Grid item xs={6} style={{ textAlign: "end" }} className={classes.grid}>
              <Typography className={classes.typography}>
                {new bigNumber(props.lpTokensStaked).toFixed(8)}
              </Typography>
            </Grid>
          </Grid>
          <Grid className={classes.dotted}>
            <Grid item xs={6}>
              <Typography className={`${classes.typography} ${classes.greyTypography}`}>
                Staked Amount ({props.pool === '0' ? 'USDT' : props.pool === '1' ? 'ETH' : 'FET'})
              </Typography>
            </Grid>
            <Grid item xs={6} style={{ textAlign: "end" }} className={classes.grid}>
              <Typography className={classes.typography}>
                {new bigNumber(Math.abs(props.stakedAmount)).toFixed(props.pool === '0' ? 2 : 6)}
              </Typography>
            </Grid>
          </Grid>
          <Grid className={classes.dotted}>
            <Grid item xs={6}>
              <Typography className={`${classes.typography} ${classes.greyTypography}`}>
                Current Value ({props.pool === '1' ? 'ETH' : 'USDT'})
              </Typography>
            </Grid>
            <Grid item xs={6} style={{ textAlign: "end" }} className={classes.grid}>
              <Typography className={classes.typography}>
                {new bigNumber(props.currentValue).toFixed(props.pool === '0' ? 2 : 6)}
              </Typography>
            </Grid>
          </Grid>
          <Grid className={classes.dotted}>
            <Grid item xs={6}>
              <Typography className={`${classes.typography} ${classes.greyTypography}`}>
                Rewards Accumulated ( MTLX )
              </Typography>
            </Grid>
            <Grid item xs={6} style={{ textAlign: "end" }} className={classes.grid}>
              <Typography className={classes.typography}>
                {props.mtlxAcc} ({props.mtlxAccInUSDT} USDT)
              </Typography>
            </Grid>
          </Grid>
          <Grid className={classes.dotted}>
            <Grid item xs={6}>
              <Typography className={`${classes.typography} ${classes.greyTypography}`}>
                Rewards Claimed
              </Typography>
            </Grid>
            <Grid item xs={6} style={{ textAlign: "end" }} className={classes.grid}>
              <Typography className={classes.typography}>
                {Math.abs(parseFloat(props.rewardsCollected).toFixed(3))} ({Math.abs(parseFloat(props.rewardsCollectedUSDT).toFixed(3))} USDT)
              </Typography>
            </Grid>
          </Grid>
          <Grid className={classes.dotted}>
            <Grid item xs={6}>
              <Typography className={`${classes.typography} ${classes.greyTypography}`}>
                Pool Share %
              </Typography>
            </Grid>
            <Grid item xs={6} style={{ textAlign: "end" }} className={classes.grid}>
              <Typography className={classes.typography}>
                {props.poolShare}
              </Typography>
            </Grid>
          </Grid>
        </CardContent>
      </Grid>
      <Grid item md={6} sm={12}>
        <Grid item xs={12}>
          <div className={classes.headerContainer} style={{ marginBottom: 8 }}>
            <Typography className={classes.cardTitle}>
              MTLX Left to Farm
            </Typography>
          </div>
          <CardContent className={classes.cardContent}>
            <Slider price={props.tokensPercent ? (props.tokensPercent+27.5):0} value={getProperFormat()} />
          </CardContent>
        </Grid>
        <Grid item xs={12}>
          <Box className={classes.netvalue}>
            <div className={`${classes.dotted}`} style={{ margin: '9% 0' }}>
              <Typography className={classes.cardTitle} style={{ fontSize: 20, fontWeight: 'bold' }}>Net Value Increase</Typography>
              <Typography className={classes.cardTitle} style={{ fontSize: 30, fontWeight: 'bold', color: '#F97E5D', margin: '0 0 0 auto' }}>{props.valueIncrease}%</Typography>
            </div>
            {/* <CardContent className={classes.cardContent} style={{ padding: 20, display: 'flex', color: '#7C7C7D', fontSize: 14 }}>
              <div>
                <img src={Info} alt='info' />
              </div>
              &nbsp;&nbsp;
              <Typography>
                There are many variations of passages of Lorem Ipsum available, but the majority
                have suffered alteration in some form, by injected humour, or randomised words
                which don't look even slightly believable.
              </Typography>
            </CardContent> */}
          </Box>
        </Grid>
      </Grid>
    </Grid >
  );
};

export default StakeCard;