import proxyMainnet from '../ABI/PROXY_ABI.json';

class Proxy {
  constructor(address, networkId) {
    this.UserContract = new window.web3.eth.Contract(proxyMainnet, address);
  }

  getInstance = async () => this.UserContract;
}

let contract = null
const getContract = (address, networkId) => {
  contract = new Proxy(address, networkId);
  Object.freeze(contract);
  return contract;
}

export default getContract;