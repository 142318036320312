import React, { useState } from 'react';
import CardContent from '@material-ui/core/CardContent';
import { Typography, Box, Tooltip } from '@material-ui/core';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { TokenAmount, Token, Fetcher, ChainId } from "@uniswap/sdk";
import BigNumber from 'bignumber.js';
import { multiplier, contractAddresses, usdtMultiplier, slippage, gasFee, gasLimit } from '../../constants';
import FarmPanel from '../../component/FarmPanel';
import RedeemPanel from '../../component/RedeemPanel';
import useStyles from './index.style';
import { ethers } from 'ethers';

function TabPanel(props) {
  const classes = useStyles()
  const { children, value, index, ...other } = props;

  return (
    <Box
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      className={classes.tabPanel}
      {...other}
    >
      {value === index && (
        <Box p={1}>
          <Typography component={'span'}>{children}</Typography>
        </Box>
      )}
    </Box>
  );
}

const TransactionPanel = (props) => {
  const [stakeInput, setStakeInput] = useState('');
  const [errorText, setErrorText] = useState('');
  const [tab, setTab] = useState(0);
  const tabsTitle = ["Farm", "Redeem"]

  const classes = useStyles();
  const provider = new ethers.providers.InfuraProvider("mainnet", process.env.REACT_APP_INFURA_PROVIDER)

  const handleChange = (event, newValue) => {
    setTab(newValue);
  };

  const setMaxStakeInput = () => {
    setStakeInput(props.usdtBalance);
  };

  const onStakeInputChange = (event) => {
    setErrorText('');
    setStakeInput(event.target.value);
  };

  const stake = async () => {

    if (stakeInput === '') {
      setErrorText('Please enter an amount');
      return;
    }

    if (parseFloat(stakeInput) <= 0) {
      setErrorText('Amount can not be zero please enter valid amount');
      return;
    }

    if (parseFloat(stakeInput) > props.usdtBalance) {
      setErrorText(`Insufficient wallet balance (${props.usdtBalance})`);
      return;
    }

    try {
      props.setLoading(true);
      if (props.pool === '0')
        props.setLoadingText('Checking Allowanc of USDT...');
      else if (props.pool === '1')
        props.setLoadingText('Checking Allowanc of ETH...');
      else
        props.setLoadingText('Checking Allowanc of FET...');

      let usdtBeforeStaking = await props.mrc20Contract.methods.balanceOf(props.address).call();
      if (props.pool === '1') {
        usdtBeforeStaking = await window.web3.eth.getBalance(props.address);
      }

      const address = props.address;
      const pendingMTLX = await props.amplifyContract.methods.pendingMTLX(props.pool, address).call();
      // const contracts = props.drizzle.contracts;
      let stake = '' + stakeInput;
      const index = stake.indexOf('.');
      if (index >= 0) {
        if (props.pool === '0') {
          stake = stake.substr(0, index + 6);
        } else {
          stake = stake.substr(0, index + 18);
        }
      }
      let amount = 0;
      let usdtToSupply = 0;

      if (props.pool === '0') {
        amount = window.web3.utils.toBN(new BigNumber(stake).multipliedBy(new BigNumber(10).pow(usdtMultiplier)));
        usdtToSupply = window.web3.utils.toBN(new BigNumber(stake).div(2).multipliedBy(new BigNumber(10).pow(usdtMultiplier)));
      } else if (props.pool === '1') {
        amount = window.web3.utils.toBN(new BigNumber(stake).multipliedBy(new BigNumber(10).pow(multiplier)));
        usdtToSupply = window.web3.utils.toBN(window.web3.utils.toWei(stake.toString())).div(window.web3.utils.toBN('2'))
      } else {
        amount = window.web3.utils.toBN(new BigNumber(stake).multipliedBy(new BigNumber(10).pow(multiplier)));
        usdtToSupply = window.web3.utils.toBN(new BigNumber(stake).div(2).multipliedBy(new BigNumber(10).pow(multiplier)));
      }

      if (index >= 0) {
        if (props.pool === '0') {
          stake = stake.substr(0, index + 4);
        } else {
          stake = stake.substr(0, index + 16);
        }
      }

      let slippageTolerance = 0;

      if (props.pool === '0') {
        slippageTolerance = window.web3.utils.toBN(new BigNumber(stake).minus(new BigNumber(stake).multipliedBy(0.01)).multipliedBy(new BigNumber(10).pow(usdtMultiplier - 1)));
      } else if (props.pool === '1') {
        slippageTolerance = window.web3.utils.toBN(new BigNumber(stake).minus(new BigNumber(stake).minus(0.1).multipliedBy(0.01)).multipliedBy(new BigNumber(10).pow(multiplier - 1)));
      } else {
        slippageTolerance = window.web3.utils.toBN(new BigNumber(stake).minus(new BigNumber(stake).multipliedBy(0.96)).multipliedBy(new BigNumber(10).pow(multiplier - 1)));
      }

      const allowance = await props.mrc20Contract.methods.allowance(address, contractAddresses.proxy).call();
      let condition0;
      if (props.pool === '0') {
        condition0 = new BigNumber(allowance).div(new BigNumber(10 ** usdtMultiplier)).eq(new BigNumber(0));
      }
      if (props.pool === '2') {
        condition0 = new BigNumber(allowance).div(new BigNumber(10 ** multiplier)).eq(new BigNumber(0));
      }

      if (props.pool !== '1')
        if (!condition0) {
          const conditionAmount = new BigNumber(amount.toString()).isGreaterThan(new BigNumber(allowance));
          if (conditionAmount) {
            props.setLoadingText(`Insufficient amount already approved. Resetting approval on ${props.pool === '0' ? 'USDT' : 'FET'} contract...`);
            await props.mrc20Contract.methods.approve(contractAddresses.proxy, 0).send({ from: address, gasFee: gasFee });
            props.setLoadingText(`Approving ${props.pool === '0' ? 'USDT' : 'FET'}...`);
            await props.mrc20Contract.methods.approve(contractAddresses.proxy, amount).send({ from: address, gasFee: gasFee });
          }
        } else {
          props.setLoadingText(`Approving ${props.pool === '0' ? 'USDT' : 'FET'}...`);
          await props.mrc20Contract.methods.approve(contractAddresses.proxy, amount).send({ from: address, gasFee: gasFee });
        }

      let originalUserBalance = await props.lpContract.methods.balanceOf(address).call();
      if (props.pool === '1')
        originalUserBalance = await props.lpethContract.methods.balanceOf(address).call();
      if (props.pool === '2')
        originalUserBalance = await props.lpfetContract.methods.balanceOf(address).call();

      // await props.mrc20Contract.methods.approve(contractAddresses.proxy, amount).send({ from: address });

      if (props.pool === '0') {
        props.setLoadingText('Swapping 50% of USDT for MTLX and depositing in the Uniswap MTLX:USDT pool...');
      } else if (props.pool === '1') {
        props.setLoadingText('Swapping 50% of ETH for MTLX and depositing in the Uniswap MTLX:ETH pool...');
      } else {
        props.setLoadingText('Swapping 50% of FET for MTLX and depositing in the Uniswap MTLX:FET pool...');
      }
      let tokenInData = await props.hrc20inContract.methods.inject([contractAddresses.usdt], [amount]).encodeABI();
      if (props.pool === '1') {
        tokenInData = await props.hrc20inContract.methods.inject([contractAddresses.eth], [amount]).encodeABI();
      }
      if (props.pool === '2') {
        tokenInData = await props.hrc20inContract.methods.inject([contractAddresses.fet], [amount]).encodeABI();
      }
      const minMTLXExpected = await props.uniswapContract.methods.getAmountsOut(
        usdtToSupply,
        [props.pool === '0' ? contractAddresses.usdt : props.pool === '1' ? contractAddresses.eth : contractAddresses.fet, contractAddresses.mtlx]
      ).call();


      const bigMTLXExpected = window.web3.utils.toBN(minMTLXExpected[1]);

      let uniswap_supply_data, uniswap_swap_data;
      if (props.pool === '0') {
        uniswap_swap_data = props.huniswapContract.methods.swapExactTokensForTokens(
          usdtToSupply,
          bigMTLXExpected,
          [contractAddresses.usdt, contractAddresses.mtlx]
        ).encodeABI();

        uniswap_supply_data = props.huniswapContract.methods.addLiquidity(
          contractAddresses.usdt,
          contractAddresses.mtlx,
          usdtToSupply,
          bigMTLXExpected,
          slippageTolerance,
          slippageTolerance,
        ).encodeABI();

        await props.proxyContract.methods.batchExec(
          [contractAddresses.HERC20TokensIn, contractAddresses.HUniswapV2, contractAddresses.HUniswapV2],
          [tokenInData, uniswap_swap_data, uniswap_supply_data]
        ).send({ from: address });
      } else if (props.pool === '1') {
        uniswap_swap_data = props.huniswapContract.methods.swapExactETHForTokens(
          usdtToSupply,
          window.web3.utils.toBN(bigMTLXExpected).muln(0.96),
          [contractAddresses.eth, contractAddresses.mtlx]
        ).encodeABI();

        uniswap_supply_data = props.huniswapContract.methods.addLiquidityETH(usdtToSupply,
          contractAddresses.mtlx, bigMTLXExpected, '1', '1').encodeABI()

        await props.proxyContract.methods.batchExec(
          [contractAddresses.HUniswapV2, contractAddresses.HUniswapV2],
          [uniswap_swap_data, uniswap_supply_data]
        ).send({ from: address, gasFee: gasFee, value: amount });
      } else {
        uniswap_swap_data = props.huniswapContract.methods.swapExactTokensForTokens(
          usdtToSupply,
          bigMTLXExpected,
          [contractAddresses.fet, contractAddresses.mtlx]
        ).encodeABI();

        uniswap_supply_data = props.huniswapContract.methods.addLiquidity(
          contractAddresses.fet,
          contractAddresses.mtlx,
          usdtToSupply,
          bigMTLXExpected,
          slippageTolerance,
          slippageTolerance,
        ).encodeABI();

        await props.proxyContract.methods.batchExec(
          [contractAddresses.HERC20TokensIn, contractAddresses.HUniswapV2, contractAddresses.HUniswapV2],
          [tokenInData, uniswap_swap_data, uniswap_supply_data]
        ).send({ from: address });
      }

      props.setLoadingText('Approving Uni V2 LP tokens...');
      let userBalance = await props.lpContract.methods.balanceOf(address).call();
      if (props.pool === '1')
        userBalance = await props.lpethContract.methods.balanceOf(address).call();
      if (props.pool === '2')
        userBalance = await props.lpfetContract.methods.balanceOf(address).call();

      const userBalanceToStake = window.web3.utils.toBN(userBalance).sub(window.web3.utils.toBN(originalUserBalance));
      // const userBalanceToStake = window.web3.utils.toBN(new BigNumber(userBalance).div(10 ** multiplier).minus(new BigNumber(originalUserBalance).div(10 ** multiplier)).multipliedBy(10 ** multiplier).toNumber());

      if (props.pool === '0')
        await props.lpContract.methods.approve(
          contractAddresses.amplify,
          userBalanceToStake
        ).send({ from: address });
      else if (props.pool === '1')
        await props.lpethContract.methods.approve(
          contractAddresses.amplify,
          userBalanceToStake
        ).send({ from: address });
      else
        await props.lpfetContract.methods.approve(
          contractAddresses.amplify,
          userBalanceToStake
        ).send({ from: address });

      let loadingText = 'Depositing Uni V2 LP tokens in Amplify.';
      if (props.mtlxRewards > 0) {
        loadingText += ' This will also claim all your MTLX rewards accumulated so far.'
      }
      loadingText += '..';
      props.setLoadingText(loadingText);
      await props.amplifyContract.methods.deposit(props.pool, userBalanceToStake).send({ from: address, gasFee: gasFee });

      setStakeInput('');
      let usdtAfterStaking, diff;
      if (props.pool === '0') {
        usdtAfterStaking = await props.mrc20Contract.methods.balanceOf(props.address).call();
        diff = new BigNumber(usdtBeforeStaking).minus(new BigNumber(usdtAfterStaking)).div(10 ** usdtMultiplier).toString();
      } else if (props.pool === '1') {
        usdtAfterStaking = await window.web3.eth.getBalance(props.address);
        diff = new BigNumber(usdtBeforeStaking).minus(new BigNumber(usdtAfterStaking)).div(10 ** multiplier).toString();
      } else {
        usdtAfterStaking = await props.mrc20Contract.methods.balanceOf(props.address).call();
        diff = new BigNumber(usdtBeforeStaking).minus(new BigNumber(usdtAfterStaking)).div(10 ** multiplier).toString();
      }
      let mtlxReward = new BigNumber(pendingMTLX).div(new BigNumber(10 ** multiplier)).toNumber();

      await props.updateStakedAmount(address, parseFloat(diff), mtlxReward, props.pool === '0' ? 'USDT' : props.pool === '1' ? 'ETH' : 'FET');
      props.updateBalances();
      props.setLoadingText('Loading');
      props.setLoading(false);
    }
    catch (err) {
      console.log(err);
      props.updateBalances();
      props.setLoadingText('Loading');
      props.setLoading(false);
    }
  };

  const claimMTLXRewards = async () => {
    try {
      props.setLoading(true);
      const address = props.address;
      const pendingMTLX = await props.amplifyContract.methods.pendingMTLX(parseInt(props.pool), address).call();
      await props.amplifyContract.methods.withdraw(parseInt(props.pool), 0).send({ from: address, gasFee: gasFee });
      const mtlxReward = new BigNumber(pendingMTLX).div(new BigNumber(10 ** multiplier)).toNumber();
      await props.updateStakedAmount(address, 0, mtlxReward, props.pool === '0' ? 'USDT' : props.pool === '1' ? 'ETH' : 'FET');
      props.updateBalances();
      props.setLoading(false);
    }
    catch (err) {
      console.log(err);
      props.updateBalances();
      props.setLoading(false);
    }
  };

  const unstakeETH = async () => {
    try {
      props.setLoading(true);
      props.setLoadingText('Withdrawing Uni V2 LP tokens from Amplify...');
      const address = props.address;
      let lpTokenWithdrawnBefore = await props.lpethContract.methods.balanceOf(address).call();
      const userInfo = await props.amplifyContract.methods.userInfo(1, address).call();
      // const pendingMTLX = await props.drizzle.contracts.Amplify.methods.pendingMTLX(1, address).call();
      await props.amplifyContract.methods.withdraw(1, window.web3.utils.toBN(userInfo.amount)).send({ from: address, gasFee: gasFee });
      const savedStakedAmount = await props.getStakedAmount(address, 'ETH', props.networkSelected);
      // const mtlxReward = new BigNumber(pendingMTLX).div(new BigNumber(10 ** multiplier)).toNumber();
      const rewards = await props.getMTLXRewards(address, 'ETH', props.networkSelected);
      await props.updateStakedAmount(address, savedStakedAmount * (-1), rewards * (-1), 'ETH');

      props.setLoadingText('Approving Uni V2 LP tokens...');

      let lpTokenWithdrawnAfter = await props.lpethContract.methods.balanceOf(address).call();

      let lpTokenWithdrawn = window.web3.utils.toBN(lpTokenWithdrawnAfter).sub(window.web3.utils.toBN(lpTokenWithdrawnBefore));

      await props.lpethContract.methods.approve(contractAddresses.proxy, window.web3.utils.toBN(lpTokenWithdrawn)).send({ from: address, gasFee: gasFee });
      const tokenInData = await props.hrc20inContract.methods.inject(
        [contractAddresses.lpETH],
        [window.web3.utils.toBN(lpTokenWithdrawn)]
      ).encodeABI();

      props.setLoadingText('Redeeming UNI V2 LP tokens and swapping for ETH...');

      const ethToken = new Token(
        ChainId.MAINNET,
        contractAddresses.eth,
        multiplier,
        "WETH",
        "WrappedETH"
      );

      const mtlxToken = new Token(
        ChainId.MAINNET,
        contractAddresses.mtlx,
        multiplier,
        "MTLX",
        "Mettalex"
      );

      Fetcher.fetchPairData(ethToken, mtlxToken, provider).then(async (pair) => {
        const lpTokensTotalSupply = await props.lpethContract.methods.totalSupply().call();
        const kLast = await props.lpethContract.methods.kLast().call();
        const lpToken = new Token(
          ChainId.MAINNET,
          contractAddresses.lpETH,
          multiplier,
          await props.lpethContract.methods.symbol().call(),
          await props.lpethContract.methods.name().call()
        );
        const ethValue = pair.getLiquidityValue(
          ethToken,
          new TokenAmount(lpToken, window.web3.utils.toBN(lpTokensTotalSupply)),
          new TokenAmount(lpToken, window.web3.utils.toBN(lpTokenWithdrawn)),
          true,
          kLast
        );

        const mtlxValue = pair.getLiquidityValue(
          mtlxToken,
          new TokenAmount(lpToken, window.web3.utils.toBN(lpTokensTotalSupply)),
          new TokenAmount(lpToken, window.web3.utils.toBN(lpTokenWithdrawn)),
          true,
          kLast
        );

        const mtlxRedeemed = window.web3.utils.toBN(new BigNumber(mtlxValue.toExact()).multipliedBy(10 ** multiplier)).muln(0.96);
        const minETHOut = window.web3.utils.toBN(new BigNumber(ethValue.toExact()).multipliedBy(10 ** multiplier)).muln(0.96);

        const mtlxToETHExpected = await props.uniswapContract.methods.getAmountsOut(
          mtlxRedeemed,
          [contractAddresses.mtlx, contractAddresses.eth]
        ).call();
        const minMTLXToETH = window.web3.utils.toBN(mtlxToETHExpected[1]).muln(0.96);

        const uni_redeem_tx = await props.huniswapContract.methods.removeLiquidityETH(contractAddresses.mtlx, window.web3.utils.toBN(lpTokenWithdrawn), mtlxRedeemed, minETHOut).encodeABI()

        const uni_mtlx_swap = await props.huniswapContract.methods.swapExactTokensForETH(mtlxRedeemed, minMTLXToETH, [contractAddresses.mtlx, contractAddresses.eth]).encodeABI()

        await props.proxyContract.methods.batchExec([contractAddresses.HERC20TokensIn, contractAddresses.HUniswapV2, contractAddresses.HUniswapV2], [tokenInData, uni_redeem_tx, uni_mtlx_swap]).send({ from: address, gasFee: gasFee })

        // const savedStakedAmount = await props.getStakedAmount(address);
        // const mtlxReward = new BigNumber(pendingMTLX).div(new BigNumber(10 ** multiplier)).toNumber();
        // props.updateStakedAmount(address, savedStakedAmount * (-1), mtlxReward);
        props.updateBalances();
        props.setLoadingText('Loading');
        props.setLoading(false);
      }).catch(err => {
        console.log(err);
        props.updateBalances();
        props.setLoadingText('Loading');
        props.setLoading(false);
      });

    }
    catch (err) {
      console.log(err);
      const address = props.address;
      const savedStakedAmount = await props.getStakedAmount(address);
      await props.updateStakedAmount(address, savedStakedAmount * (-1));
      props.updateBalances();
      props.setLoadingText('Loading');
      props.setLoading(false);
    }
  };

  const unstakeUSDT = async () => {
    try {
      props.setLoading(true);
      props.setLoadingText('Withdrawing Uni V2 LP tokens from Amplify...');
      const address = props.address;
      let lpTokenWithdrawnBefore = await props.lpContract.methods.balanceOf(address).call();
      const userInfo = await props.amplifyContract.methods.userInfo(0, address).call();
      // const pendingMTLX = await props.drizzle.contracts.Amplify.methods.pendingMTLX(0, address).call();
      await props.amplifyContract.methods.withdraw(0, window.web3.utils.toBN(userInfo.amount)).send({ from: address, gasFee: gasFee });
      const savedStakedAmount = await props.getStakedAmount(address, 'USDT', props.networkSelected);
      // const mtlxReward = new BigNumber(pendingMTLX).div(new BigNumber(10 ** multiplier)).toNumber();
      const rewards = await props.getMTLXRewards(address, 'USDT', props.networkSelected);
      await props.updateStakedAmount(address, savedStakedAmount * (-1), rewards * (-1), 'USDT');

      props.setLoadingText('Approving Uni V2 LP tokens...');
      let lpTokenWithdrawnAfter = await props.lpContract.methods.balanceOf(address).call();

      let lpTokenWithdrawn = window.web3.utils.toBN(lpTokenWithdrawnAfter).sub(window.web3.utils.toBN(lpTokenWithdrawnBefore));
      await props.lpContract.methods.approve(contractAddresses.proxy, window.web3.utils.toBN(lpTokenWithdrawn)).send({ from: address, gasFee: gasFee });
      let tokenInData = await props.hrc20inContract.methods.inject(
        [contractAddresses.lp],
        [window.web3.utils.toBN(lpTokenWithdrawn)]
      ).encodeABI();

      props.setLoadingText('Redeeming UNI V2 LP tokens and swapping for USDT...');

      const usdtToken = new Token(
        ChainId.MAINNET,
        contractAddresses.usdt,
        usdtMultiplier,
        "USDT",
        "USDTether"
      );

      const mtlxToken = new Token(
        ChainId.MAINNET,
        contractAddresses.mtlx,
        multiplier,
        "MTLX",
        "Mettalex"
      );

      Fetcher.fetchPairData(usdtToken, mtlxToken, provider).then(async (pair) => {
        let lpTokensTotalSupply = await props.lpContract.methods.totalSupply().call();
        let kLast = await props.lpContract.methods.kLast().call();
        let lpToken = new Token(
          ChainId.MAINNET,
          contractAddresses.lp,
          multiplier,
          await props.lpContract.methods.symbol().call(),
          await props.lpContract.methods.name().call()
        );
        let usdtValue = pair.getLiquidityValue(
          usdtToken,
          new TokenAmount(lpToken, window.web3.utils.toBN(lpTokensTotalSupply)),
          new TokenAmount(lpToken, window.web3.utils.toBN(lpTokenWithdrawn)),
          true,
          kLast
        );

        const mtlxValue = pair.getLiquidityValue(
          mtlxToken,
          new TokenAmount(lpToken, window.web3.utils.toBN(lpTokensTotalSupply)),
          new TokenAmount(lpToken, window.web3.utils.toBN(lpTokenWithdrawn)),
          true,
          kLast
        );

        let mtlxRedeemed = window.web3.utils.toBN(new BigNumber(mtlxValue.toExact()).multipliedBy(10 ** multiplier)).muln(0.96);
        let minUSDTOut = window.web3.utils.toBN(new BigNumber(usdtValue.toExact()).multipliedBy(10 ** usdtMultiplier)).muln(0.96);
        const uniRedeemTx = await props.huniswapContract.methods.removeLiquidity(
          contractAddresses.usdt,
          contractAddresses.mtlx,
          window.web3.utils.toBN(lpTokenWithdrawn),
          minUSDTOut,
          mtlxRedeemed
        ).encodeABI();

        const mtlxToUSDTExpected = await props.uniswapContract.methods.getAmountsOut(
          mtlxRedeemed,
          [contractAddresses.mtlx, contractAddresses.usdt]
        ).call();

        const minMTLXToUSDT = window.web3.utils.toBN(mtlxToUSDTExpected[1]).muln(slippage);

        const UniSwapMtlx = await props.huniswapContract.methods.swapExactTokensForTokens(
          mtlxRedeemed,
          minMTLXToUSDT,
          [contractAddresses.mtlx, contractAddresses.usdt]
        ).encodeABI();

        await props.proxyContract.methods.batchExec(
          [contractAddresses.HERC20TokensIn, contractAddresses.HUniswapV2, contractAddresses.HUniswapV2],
          [tokenInData, uniRedeemTx, UniSwapMtlx]
        ).send({ from: address, gasFee: gasFee });

        // const savedStakedAmount = await props.getStakedAmount(address);
        // const mtlxReward = new BigNumber(pendingMTLX).div(new BigNumber(10 ** multiplier)).toNumber();
        // props.updateStakedAmount(address, savedStakedAmount * (-1), mtlxReward);
        props.updateBalances();
        props.setLoadingText('Loading');
        props.setLoading(false);
      }).catch(err => {
        console.log(err);
        props.updateBalances();
        props.setLoadingText('Loading');
        props.setLoading(false);
      });

    }
    catch (err) {
      console.log(err);
      const address = props.address;
      const savedStakedAmount = await props.getStakedAmount(address);
      await props.updateStakedAmount(address, savedStakedAmount * (-1));
      props.updateBalances();
      props.setLoadingText('Loading');
      props.setLoading(false);
    }
  };

  const unstakeFET = async () => {
    try {
      props.setLoading(true);
      props.setLoadingText('Withdrawing Uni V2 LP tokens from Amplify...');
      const address = props.address;
      let lpTokenWithdrawnBefore = await props.lpfetContract.methods.balanceOf(address).call();
      const userInfo = await props.amplifyContract.methods.userInfo(2, address).call();
      // const pendingMTLX = await props.drizzle.contracts.Amplify.methods.pendingMTLX(0, address).call();
      await props.amplifyContract.methods.withdraw(2, window.web3.utils.toBN(userInfo.amount)).send({ from: address, gasFee: gasFee });
      const savedStakedAmount = await props.getStakedAmount(address, 'FET', props.networkSelected);
      // const mtlxReward = new BigNumber(pendingMTLX).div(new BigNumber(10 ** multiplier)).toNumber();
      const rewards = await props.getMTLXRewards(address, 'FET', props.networkSelected);
      await props.updateStakedAmount(address, savedStakedAmount * (-1), rewards * (-1), 'FET');

      props.setLoadingText('Approving UNI V2 LP tokens...');
      let lpTokenWithdrawnAfter = await props.lpfetContract.methods.balanceOf(address).call();

      let lpTokenWithdrawn = window.web3.utils.toBN(lpTokenWithdrawnAfter).sub(window.web3.utils.toBN(lpTokenWithdrawnBefore));
      await props.lpfetContract.methods.approve(contractAddresses.proxy, window.web3.utils.toBN(lpTokenWithdrawn)).send({ from: address, gasFee: gasFee });
      let tokenInData = await props.hrc20inContract.methods.inject(
        [contractAddresses.lpFET],
        [window.web3.utils.toBN(lpTokenWithdrawn)]
      ).encodeABI();

      props.setLoadingText('Redeeming UNI V2 LP tokens and swapping for FET...');

      const fetToken = new Token(
        ChainId.MAINNET,
        contractAddresses.fet,
        multiplier,
        "FET",
        "Fetch.AI"
      );

      const mtlxToken = new Token(
        ChainId.MAINNET,
        contractAddresses.mtlx,
        multiplier,
        "MTLX",
        "Mettalex"
      );

      Fetcher.fetchPairData(fetToken, mtlxToken, provider).then(async (pair) => {
        let lpTokensTotalSupply = await props.lpfetContract.methods.totalSupply().call();
        let kLast = await props.lpfetContract.methods.kLast().call();
        let lpToken = new Token(
          ChainId.MAINNET,
          contractAddresses.lpFET,
          multiplier,
          await props.lpfetContract.methods.symbol().call(),
          await props.lpfetContract.methods.name().call()
        );
        let fetValue = pair.getLiquidityValue(
          fetToken,
          new TokenAmount(lpToken, window.web3.utils.toBN(lpTokensTotalSupply)),
          new TokenAmount(lpToken, window.web3.utils.toBN(lpTokenWithdrawn)),
          true,
          kLast
        );

        const mtlxValue = pair.getLiquidityValue(
          mtlxToken,
          new TokenAmount(lpToken, window.web3.utils.toBN(lpTokensTotalSupply)),
          new TokenAmount(lpToken, window.web3.utils.toBN(lpTokenWithdrawn)),
          true,
          kLast
        );

        let mtlxRedeemed = window.web3.utils.toBN(new BigNumber(mtlxValue.toExact()).multipliedBy(10 ** multiplier)).muln(0.96);
        let minFETOut = window.web3.utils.toBN(new BigNumber(fetValue.toExact()).multipliedBy(10 ** multiplier)).muln(0.96);

        const uniRedeemTx = await props.huniswapContract.methods.removeLiquidity(
          contractAddresses.fet,
          contractAddresses.mtlx,
          window.web3.utils.toBN(lpTokenWithdrawn),
          minFETOut,
          mtlxRedeemed
        ).encodeABI();

        const mtlxToFETExpected = await props.uniswapContract.methods.getAmountsOut(
          mtlxRedeemed,
          [contractAddresses.mtlx, contractAddresses.fet]
        ).call();

        const minMTLXToFET = window.web3.utils.toBN(mtlxToFETExpected[1]).muln(0.96);

        const UniSwapMtlx = await props.huniswapContract.methods.swapExactTokensForTokens(
          mtlxRedeemed,
          minMTLXToFET,
          [contractAddresses.mtlx, contractAddresses.fet]
        ).encodeABI();

        await props.proxyContract.methods.batchExec(
          [contractAddresses.HERC20TokensIn, contractAddresses.HUniswapV2, contractAddresses.HUniswapV2],
          [tokenInData, uniRedeemTx, UniSwapMtlx]
        ).send({ from: address, gasFee: gasFee, gasLimit: gasLimit });

        // const savedStakedAmount = await props.getStakedAmount(address);
        // const mtlxReward = new BigNumber(pendingMTLX).div(new BigNumber(10 ** multiplier)).toNumber();
        // props.updateStakedAmount(address, savedStakedAmount * (-1), mtlxReward);
        props.updateBalances();
        props.setLoadingText('Loading');
        props.setLoading(false);
      }).catch(err => {
        console.log(err);
        props.updateBalances();
        props.setLoadingText('Loading');
        props.setLoading(false);
      });
    }
    catch (err) {
      console.log(err);
      const address = props.address;
      const savedStakedAmount = await props.getStakedAmount(address);
      await props.updateStakedAmount(address, savedStakedAmount * (-1));
      props.updateBalances();
      props.setLoadingText('Loading');
      props.setLoading(false);
    }
  };

  return (
    <>
      <CardContent className={classes.cardContent}>
        <Tabs TabIndicatorProps={{
          style: {
            backgroundColor: "#68dbda"
          }
        }} value={tab} onChange={handleChange} className={classes.tabs} >
          <Tab className={tab === 0 ? classes.tab : classes.disabledTab} label={
            tabsTitle[0]
          } />
          <Tab className={tab === 1 ? classes.tab : classes.disabledTab} label={
            tabsTitle[1]
          } />
          <Tooltip
            style={{ display: "inline", marginRight: "5px" }}
          >
            <Typography className={classes.icon}>{props.pool === '0' ? 'USDT' : props.pool === '1' ? 'ETH' : 'FET'}</Typography>
          </Tooltip>
        </Tabs>
        <TabPanel value={tab} index={0} className={`${classes.tabContainer} tabs`}>
          <FarmPanel
            setMaxStakeInput={setMaxStakeInput}
            stake={stake}
            onStakeInputChange={onStakeInputChange}
            value={stakeInput}
            errorText={errorText}
            pool={props.pool}
          />
        </TabPanel>
        <TabPanel value={tab} index={1} className={`${classes.tabContainer} tabs`}>
          <RedeemPanel
            unstakeUSDT={props.pool === '0' ? unstakeUSDT : props.pool === '1' ? unstakeETH : unstakeFET}
            stakedAmount={props.stakedAmount}
            claimMTLXRewards={claimMTLXRewards}
            mtlxRewards={props.mtlxRewards}
            lpTokensStaked={props.lpTokensStaked}
            pool={props.pool}
          />
        </TabPanel>
      </CardContent>
    </>
  );
};

export default TransactionPanel;