import lpMainnet from '../ABI/LPETH_ABI.json';

class LPETH {
  constructor(address) {
    this.UserContract = new window.web3.eth.Contract(lpMainnet, address);
  }

  getInstance = async () => this.UserContract;
}

let contract = null
const getContract = (address) => {
  contract = new LPETH(address);
  Object.freeze(contract);
  return contract;
}

export default getContract;